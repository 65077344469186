<template>
    <div>
      <FormulaBanner :formulaCode="formulaCode" :title="title"/>
      <div class="index">
        <!-- 表单 -->
        <div>
          <div class="top_nav"><span></span>{{ title }}
          </div>
          <div class="formBody">
            <!-- 表单 -->
            <MyPatient :memberId="memberId" :selectValue="selectValue" @change="userChange"/>
  
            <TemplateFiveList :list-data="listData" :selectValue="listSelect" :type="'check'"
                              @change="listChange"></TemplateFiveList>
            <div v-if="compute === true" style="margin: 16px;display: flex;justify-content: center;">
              <van-button block class="formula-template-button" type="info" @click="submit" style="width:90px">提交</van-button>
            </div>
            <!--          <div v-if="countText || countText !== ''" class="CalculationResultsBox">-->
            <!--            <div class="CalculationResults"><span>计算结果</span></div>-->
            <!--            &lt;!&ndash; 具体标准 &ndash;&gt;-->
            <!--            <div>-->
            <!--              <div class="CalculationDetail"><span></span>{{ result }}分 {{ countText }}</div>-->
            <!--            </div>-->
            <!--          </div>-->
          </div>
        </div>
        <CalculationResults :countText="countValue" :countTextList="countTextList" v-if="show"></CalculationResults>
        <!-- 后台设置的值 -->
        <FormulaExplain :explain-data="tableData.explain"></FormulaExplain>
        <!-- 参考文献 -->
        <FormulaReferences :references-data="tableData.references"></FormulaReferences>
      </div>
  
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
            :columns="columns"
            show-toolbar
            title="应激指数"
            @cancel="onCancel"
            @confirm="onConfirm"
        />
      </van-popup>
  
    </div>
  
  </template>
  
  <script>
  import MyPatient from '@/components/MyPatient.vue'
  import FormulaBanner from '@/components/FormulaBanner.vue'
  import {Toast} from "vant";
  import {getAdmins} from "@/utils/adminDate";
  import {setById} from "@/service/base";
  import {getFormula, getFormulaHuiXian,postFormula } from "@/service/BodyMassIndex_api";  
  import FormulaExplain from "@/components/template1/FormulaExplain";
  import FormulaReferences from "@/components/template1/FormulaReferences";
  import TemplateFiveList from "@/components/Template5/TemplateFiveList";
  import CalculationResults from "@/components/CalculationResults.vue";
  
  export default {
    name: "medicalSearch",
    data() {
      return {
        compute:true,
        show:false,
        listSelect: [],
        listData: [
          {
            title: '患者存在的危险因素',
            columns: ['年龄>70岁', '6个月内心肌梗死病史','第三心音奔马律或颈外静脉怒张','明显主动脉狭窄','术前心电图显示非窦性心律， 有房性期前收缩',
                '术前任何时刻出现超过5个/min的室性期前收缩','一般内科情况差（存在下面任一情况：<br/>\nPaO₂<60mmHg；PaCO₂>50 mmHg；<br/>\nK⁺<3.0 mmol/L；BUN>18 mmol/L；<br/>\nCr>260 mmol/L；SGOT升高；慢性肝病；非心脏原因卧床等）',
                '腹腔、胸腔或主动脉手术','急诊手术'
            ],
            fenzhi: [5,10,11,3,7,7,3,3,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
          },
        //   {
        //     title: '体格检查',
        //     columns: ['第三心音奔马律或颈外静脉怒张', '明显主动脉狭窄'],
        //     fenzhi: [11,3] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        //   },
        //   {
        //     title: '心电图',
        //     columns: ['术前心电图显示非窦性心律， 有房性期前收缩', '术前任何时刻出现超过5个/ min的室性期前收缩'],
        //     fenzhi: [7,7] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        //   },
        //   {
        //     title: '一般情况',
        //     columns: ['PaO₂<60mmHg或PaCO₂>50 mmHg, K⁺<3.0 mmol/L或HCO₃²⁻<20 mmol/L, BUN>18 mmol/L或 Cr>30.0 mg/L, GOT异常, 慢性肝病, 卧床'],
        //     fenzhi: [3] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        //   },
        //   {
        //     title: '手术',
        //     columns: ['腹腔、胸腔或主动脉手术', '急诊手术'],
        //     fenzhi: [3,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        //   },
        ],
        formulaCode: '',
        shengao: '',
        yingjizhishu: '',
        title: '',
        memberId: '1111',
        selectValue: '',
        username: "",
        value: "",
        showPopover: false,
        showPicker: false,
        columns: ['立即治疗', '加强监测', '维持和监护'],
        nianling: '',
        xingbie: null,
        tableData: {},
        countText: '',
        patientId: null,
        result: null,
        yingjizhishuzhi: '',
        tizhong: '',
        xueqingnanongdu: '',
        fenzhiList: [],
        countValue:"",
        countTextList:[],
      }
    },
    components: {
      CalculationResults,
      FormulaBanner,
      MyPatient,
      FormulaExplain,
      FormulaReferences,
      TemplateFiveList,
    },
    async created() {
      await this.test()
      await this.getTableData()
    },
  
    methods: {
      listChange(result, fenzhi) {
        this.show=false
        this.listSelect = result //结果数组
        this.fenzhiList = fenzhi  //分值数组
        console.log(fenzhi)
      },
  
      coumputTetxt() {
        this.show=true
        let fenshu = 0
        function calculateScore(data, select) {
          select.value.forEach(item => {
            const index = data.columns.indexOf(item);
            if (index !== -1) {
              fenshu += data.fenzhi[index];
            }
          });
        }
  
        this.listSelect.forEach(value => {
          const data = this.listData.find(d => d.title === value.title);
          if (data) {
            calculateScore(data, value);
          }
        });

        // 7分 心功能分级Ⅱ级  心因死亡率： 2%   危及生命的并发症：5%
        if ( 0 <= fenshu && fenshu<= 5) {
          this.countText = '心功能分级Ⅰ级'
        } else if (6 <= fenshu && fenshu <= 12) {
          this.countText = '心功能分级Ⅱ级'
        } else if (13 <= fenshu && fenshu <= 25) {
          this.countText = '心功能分级Ⅲ级'
        } else if (26 <= fenshu) {
          this.countText = '心功能分级Ⅳ级'
        }
        this.result = fenshu
        this.countValue = `${fenshu}分 ${this.countText}`
      },
      async test() {
        //像接口头部传用户信息
        let arrays = await getAdmins(this.$route.query.memberId, this.$route.query.channel)
        this.channel = arrays.os
        this.version = arrays.version
        this.ip = arrays.ip
        await setById(arrays)
      },
      async getTableData() {
        const data = {
          channel: this.$router.currentRoute.query.channel,
          code: 'YSJVOFYC',
        }
        const res = await getFormula(data.channel, data.code)
        if (res.code === 0) {
          this.tableData = res.data
          this.formulaCode = res.data.code
          this.title = res.data.chineseName
        }
        if (this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' && this.$router.currentRoute.query.logId) {
          // this.compute = false
          const data = {
            id: this.$router.currentRoute.query.logId
          }
          const res = await getFormulaHuiXian(data)
          if (res.code === 0) {
            this.listSelect = res.data.content.data
            if (res.data.patientId) this.selectValue = {id: res.data.patientId, name: res.data.patientName}
            this.result = res.data.result.value
            this.countText = res.data.result.result
            this.countValue = `${res.data.result.value}分 ${res.data.result.result}`
            this.show=true
            // this.coumputTetxt()
          }
        }
      },
      async submit() {
        if (this.listSelect.length!==this.listData.length) {
          Toast.fail('请完善选择')
          return
        }
        // 如果存在一项没有选择
        for(var i = 0;i<this.listSelect.length;i++){
            if(this.listSelect[i].value.length === 0){
            Toast.fail('请完善选择')
            return;
          }
        }
        this.coumputTetxt()
        const data = {
          id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
          patientId: this.patientId,
        //   columnCode: this.tableData.columnCode,
          columnCode: 0,
          formulaCode: this.tableData.code,
          channel: this.$router.currentRoute.query.channel,
          location: this.$router.currentRoute.query.location,
          content: {
            data: this.listSelect,
          },
          result: {
            value: this.result,
            result: this.countText,
            DisplayResults:this.countValue
          }
        }
        const res = await postFormula(data)
        if (res.code === 0) {
          Toast.success('提交成功')
        } else {
          Toast.fail(res.message)
        }
      },
      calculateAge(birthDateString) {
        const today = new Date();
        const birthDate = new Date(birthDateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        // 如果今天的日期还没到生日月份和日期，则年龄减一
        if (today.getMonth() < birthDate.getMonth() ||
            (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;
      },
      // 开参考文献链接
      openLink(u) {
        if (!u) {
          return
        }
        //获取系统版本
        let userAI = navigator.userAgent;
        const isAndroid = userAI.indexOf('Android') > -1 || userAI.indexOf('Linux') > -1; //Android终端
        const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
        if (isAndroid) {
          // alert('安卓操作系统')
          // 这个是安卓操作系统
          window.open(u)
        }
        if (isIOS) {
          // 这个是ios操作系统
          // alert('ios操作系统')
          window.location.href = u
        }
      },
      yingjiClick() {
        this.showPicker = true
      },
      onConfirm(value) {
        this.yingjizhishu = value
        this.showPicker = false
      },
      onCancel() {
        this.showPicker = false
      },
      userChange(data) {
        if (data.birthday && data.birthday !== '') {
          this.nianling = this.calculateAge(data.birthday)
        } else {
          this.nianling = ''
        }
        this.xingbie = data.sex
        this.patientId = data.id
        this.selectValue = data
      },
    }
  }
  </script>
  
  
  <style lang="scss" scoped>
  //::v-deep .box-div-dis-zi{
  //  margin: 12px 0 !important;
  //}
  .index {
    background: #F6F6F6;
    padding: 10px 15px;
    min-height: 100vh;
  
    .StandardDescription {
      margin-top: 10px;
  
      .top_nav {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom: 10px;
  
        span {
          display: inline-block;
          width: 5px;
          height: 15px;
          background: #259beb;
          margin-right: 5px;
        }
      }
  
      .StandardDeatil {
        width: 100%;
        padding: 15px 10px;
        //   height: 50px;
        background-color: #fff;
        box-sizing: border-box;
      }
    }
  
    .top_nav {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom: 10px;
  
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
  
    .formBody {
      background-color: #fff;
      padding-bottom: 10px;
  
      .CalculationResultsBox {
        padding: 0 8px;
  
        .CalculationResults {
          font-size: 16px;
          color: #333333;
          font-weight: bold;
          display: flex;
          justify-content: center;
          margin-bottom: 10px
        }
  
        .CalculationDetail {
          font-size: 15px;
          color: #333333;
          // font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: start;
          margin-bottom: 10px;
          background-color: #f6f6f6;
          // height: 16px;
          span {
            display: inline-block;
            width: 3px;
            height: 24px;
            background: #fe9712;
            margin-right: 5px;
          }
        }
      }
    }
  
    .titles {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #f0f0f0;
      padding: 0 10px;
      box-sizing: border-box;
  
      .titlesName {
        font-size: 16px;
  
        i {
          color: red;
        }
      }
  
      .information {
        font-size: 16px;
        margin-right: 4px;
  
        .PleaseSelect {
          color: #b9b9b9;
        }
  
        .vanCell {
          width: 50px;
          padding: 0;
          height: 100%;
        }
      }
  
      .vBotton {
        width: 60px
      }
  
      .unit {
        height: 50px;
        //   line-height: 50px;
        display: flex;
        align-items: center;
        font-size: 16px;
        width: 20px;
      }
  
      .vanIcon {
        font-size: 13px;
        color: #a8a8a8;
        font-weight: 650;
      }
    }
  }
  
  ::v-deep .van-cell {
    padding: 0px 0px;
  }
  
  ::v-deep .van-field__control {
    height: 50px;
    font-size: 16px;
  }
  
  ::v-deep .van-field__error-message {
    position: absolute;
    top: 30px;
    left: 10px;
  }
  
  .StandardDeatil2 {
    width: 100%;
    padding: 15px 10px;
    padding-bottom: 1px;
    //   height: 50px;
    background-color: #fff;
    box-sizing: border-box;
  
    .references {
      margin-bottom: 15px
    }
  }
  
  ::v-deep p {
    padding: 0;
    margin: 0;
  }
  
  .Abutton {
  
  }
  </style>
  